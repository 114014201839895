
jQuery(document).ready(function() {

    var sliderindex = jQuery("#slider1-index").revolution({
        sliderType:"standard",
        sliderLayout:"fullscreen",
        extensions: '/website/js/revolutionslider5/extensions/',
        delay:5000,
        disableProgressBar:"on",
        lazyType: "all",
        navigation: {
            onHoverStop:"off",
            keyboardNavigation:"off",
            arrows: {
                style:"",
                enable:true,
                hide_onmobile:true,
                hide_onleave:true,
                hide_delay:200,
                hide_delay_mobile:1200,
                hide_under:0,
                hide_over:9999,
                tmp:'',
                left : {
                    h_align:"left",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                },
                right : {
                    h_align:"right",
                    v_align:"center",
                    h_offset:20,
                    v_offset:0
                }
            }
        },
        //  fullScreenAlignForce:"on", //align caption works now //deprecated v.5.2
        fullScreenOffsetContainer: "header"
    });



    // Index Banner Rotator INNER Settings
    var sliderinner = jQuery("#slider1-inner").revolution({
        sliderType:"standard",
        sliderLayout:"fullwidth",
        extensions: '/website/js/revolutionslider5/extensions/',
        delay:5000,
        disableProgressBar:"on",
        minHeight:530,
        gridwidth:1230,
        gridheight:530

        //  fullScreenAlignForce:"on", //align caption works now //deprecated v.5.2
        //fullScreenOffsetContainer: "#menu-mobile"
    });

    /* OPRAVEN BUG S PODSKACHANETO NA SLIDERA (ISSUE #1), vij i slider.scss */
    // event for when slider has been loaded
    sliderindex.on('revolution.slide.onloaded', function() {
        $("section#slider").css("min-height", "auto");
    });
    // event for when slider has been loaded
    sliderinner.on('revolution.slide.onloaded', function() {
        $("section#slider").css("min-height", "auto");
    });

});


// Slick Slider Settings
/*$(document).ready(function(){
    $('.slickslider').slick({
        autoplay: false,
        arrows: true,
        variableWidth: false,
        dots: false,
        autoplaySpeed: 3000, //default
        easing: 'linear', //linear default no need other script for linear
        //lazyLoad: 'ondemand', //activate lazy loading, img data-lazy vmesto src="", raboti pravilno samo, ako infinite: false!!!
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    arrows: false,
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    });

    $('.slickslider-subpages').slick({
        autoplay: false,
        arrows: false,
        variableWidth: true,
        dots: true,
        autoplaySpeed: 3000, //default
        easing: 'linear', //linear default no need other script for linear

        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3
    });
});*/


// MODAL LOAD MANAGEMENT PANORAMA
$('#myModal').on('hidden.bs.modal', function () {
    $(this).removeData('bs.modal');
});
// MODAL LOAD MANAGEMENT PANORAMA


$(document).ready(function(){
        // handle the mouseenter functionality
        $(".img").mouseenter(function(){
            $(this).addClass("hover");
        })
        // handle the mouseleave functionality
        .mouseleave(function(){
            $(this).removeClass("hover");
        });
});

 