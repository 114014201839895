// Scroll Smoothly JS

$( document ).ready(function() {
    //go to anchor smoothly
    $('a[href*="#"]').on('click',function (e) {
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });
});



$( document ).ready(function() {
// Like button
$( "#likeButtonOpen" ).click(function() {
  $( "#like_butt_hold" ).toggle('fast');
});
});
